<template>
  <v-container fluid>
    <div>
			<div class="d-flex">
				<CalendarioEventos />
			</div>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {
		CalendarioEventos: () => import('@/modules/events/components/Calendario/CalendarioEventos.vue')
	},
};
</script>
